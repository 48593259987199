import { Table, Tooltip, Tour } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Credit, eye_icon } from "../../../../assets/images/images";
import NoJobApplicantFound from "../../../../components/CommonComponents/NoJobApplicantFound";
import {
  fetchDemoJobsApplicantsList,
  fetchDemoJobsList,
  fetchJobsList,
} from "../../../../redux/actions/jobActions";
import { setJobPageFilters } from "../../../../redux/reducers/jobReducer";
import { formatDate } from "../../../../utils/utils";
import SkeletonLoader from "../CommonRecruiterComponant/SkeletonLoader";
import { Reset, Searchbar, SortFilter, StatusFilter } from "./MyJobs";
import "./MyJobs.css";
import { resetCompany } from "../../../../redux/reducers/companyReducer";
import { InfoCircleOutlined } from "@ant-design/icons";
import TableSpan from "../../../../components/CommonComponents/TableSpan";

const MyJobsTable = ({ activeTab, status }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialRender = useRef(true);
  const initialRenderCount = useRef(0);
  const [sortCriteria, setSortCriteria] = useState("All");
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [currentPage, setCurrentPage] = useState(1);
  const [tourOpen, setTourOpen] = useState(false);

  const companyDetails = useSelector((state) => state.company.details);
  const loading = useSelector((state) => state.job.loading);
  const jobList = useSelector((state) => state.job.jobsList);
  const demoJob = useSelector((state) => state.job.demoJobsList);
  const token = useSelector((state) => state.auth.token);
  const jobsCount = useSelector((state) => state.job.jobsCount);
  const jobPageFilters = useSelector((state) => state.job.jobPageFilters);
  const newJobList = (
    demoJob && demoJob?.title !== null ? [demoJob] : []
  ).concat(jobList || []);

  const viewButtonRef = useRef(null); // Ref for "View" button

  useEffect(() => {
    const hasSeenTour = localStorage.getItem("hasSeenTour"); // Check localStorage

    if (!hasSeenTour &&
      companyDetails?.id === undefined &&
      demoJob && // Ensure demoJob is defined
      demoJob.assessment !== null &&
      demoJob.assessment !== undefined) {
      // Trigger the tour only if it hasn't been shown before and company_id is missing
      setTourOpen(true);
      localStorage.setItem("hasSeenTour", "true"); // Set flag in localStorage
    }
  }, [companyDetails]);

  useEffect(() => {
    //on load check currentPage
    if (initialRenderCount.current < 2) {
      initialRenderCount.current = initialRenderCount.current + 1;
      setCurrentPage(jobPageFilters.current || 1);
      setSearchInput(jobPageFilters.searchInput);
      setSelectedStatus(jobPageFilters.status);
      setSortCriteria(jobPageFilters.sortBy);
      fetchJobs(
        jobPageFilters.current,
        jobPageFilters.searchInput,
        jobPageFilters.sortBy,
        jobPageFilters.status
      );
      dispatch(fetchDemoJobsList(token));
    } else {
      setCurrentPage(jobPageFilters.current || 1);
      setSearchInput(jobPageFilters.searchInput);
      setSelectedStatus(jobPageFilters.status);
      setSortCriteria(jobPageFilters.sortBy);
    }
  }, [jobPageFilters]);

  // useEffect(() => {
  //   fetchJobs(1);
  // }, [activeTab, status]);

  const handleViewJob = (jobId) => {
    navigate(`/dashboard/my-jobs/${activeTab}/${jobId}/view-jobs-data`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchJobs(page);
    dispatch(
      setJobPageFilters({
        current: page,
        status: selectedStatus,
        sortBy: sortCriteria,
        searchInput,
      })
    );
  };

  const handleSearchSubmit = (searchValue) => {
    setSearchInput(searchValue);

    setCurrentPage((prevPage) => {
      if (prevPage !== 1) {
        return 1;
      }

      fetchJobs(1);
      return prevPage;
    });
  };

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
    setCurrentPage(1); // Reset page to 1 whenever the sort criteria changes
    fetchJobs(1, searchInput, criteria, selectedStatus); // Immediately fetch with new criteria
    dispatch(
      setJobPageFilters({
        current: 1,
        status: selectedStatus,
        sortBy: criteria,
        searchInput,
      })
    );
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1); // Reset page to 1 whenever the status changes
    fetchJobs(1, searchInput, sortCriteria, status); // Immediately fetch with new status
    dispatch(
      setJobPageFilters({
        current: 1,
        status: status,
        sortBy: sortCriteria,
        searchInput,
      })
    );
  };

  const handleReset = () => {
    setSearchInput("");
    setSortCriteria("All");
    setSelectedStatus("All");
    setCurrentPage(1);
    fetchJobs(1, "", "All", "All");
  };

  const fetchJobs = (
    page,
    searchValue = searchInput,
    sortValue = sortCriteria,
    statusValue = selectedStatus
  ) => {
    // Ensure page is set to 1 on new searches
    const finalStatus = statusValue === "All" ? undefined : statusValue;
    dispatch(
      fetchJobsList(token, searchValue, undefined, sortValue, finalStatus, page)
    );
  };

  const handleViewAssessment = (interviewId) => {
    dispatch(fetchDemoJobsApplicantsList(token));
    navigate(`/dashboard/my-jobs/demo/${interviewId}/view-jobs-data`);
  };

  useEffect(() => {
    if (initialRender.current) {
      // Skip the initial render
      initialRender.current = false;
    } else {
      // This will handle changes to searchInput only after the initial render
      setCurrentPage(1);
      fetchJobs(1, searchInput, sortCriteria, selectedStatus);
      dispatch(
        setJobPageFilters({
          current: 1,
          status: selectedStatus,
          sortBy: sortCriteria,
          searchInput,
        })
      );
    }
  }, [searchInput, activeTab, status]);

  const columns = [
    {
      title: "Job Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <div data-th="Job Title">{text}</div>,
    },
    {
      title: "Publish Date",
      dataIndex: "published_date",
      key: "published_date",
      render: (text) => <div data-th="Publish Date">{formatDate(text)}</div>,
    },
    {
      title: "Applicants",
      dataIndex: "applicants_count",
      key: "applicants_count",
      render: (text) =>
        text && text !== null ? <div data-th="Applicants">{text}</div> : <>-</>,
    },
    {
      title: "Interviewed",
      dataIndex: "ai_interviewed_count",
      key: "ai_interviewed_count",
      render: (text) =>
        text && text !== null ? (
          <div data-th="AI Interviewed">{text}</div>
        ) : (
          <>-</>
        ),
    },
    {
      title: "Shortlisted",
      dataIndex: "ai_shortlisted_count",
      key: "ai_shortlisted_count",
      render: (text) =>
        text && text !== null ? (
          <div data-th="AI Shortlisted">{text}</div>
        ) : (
          <>-</>
        ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Credits{" "}
          <Tooltip
            title={
              <div style={{ textAlign: "center" }}>
                Displays the remaining credits left for interviews in this job
                posting.
              </div>
            }
            overlayStyle={{ textAlign: "center" }}
          >
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        </div>
      ),
      dataIndex: "credits_left",
      key: "credits_left",
      render: (text) =>
        text && text !== null ? (
          <div data-th="Remaining Credits">
            <img
              src={Credit}
              alt=""
              style={{ width: "20px", height: "20px", marginRight: "5px" }}
            />{" "}
            {text}
            {/* <span className="Add-credit-table">+ Add</span> */}
          </div>
        ) : (
          <>-</>
        ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <div data-th="Status">
          <TableSpan status={status} />
        </div>
      ),
    },
    {
      title: "View Details",
      dataIndex: "id",
      key: "view",
      render: (text, record) => (
        <a
          onClick={() => {
            record?.status === "Demo"
              ? handleViewAssessment(record?.interview?.id)
              : handleViewJob(record.id);
          }}
          ref={viewButtonRef}
        >
          <img src={eye_icon} alt="eye_icon" className="eye_icon" />
          {/* {record?.status === "Demo" ? "Assessment" : "View"} */}
          View
        </a>
      ),
    },
  ];

  return (
    <>
      <Tour
        open={tourOpen}
        onClose={() => setTourOpen(false)} // Close the tour when done
        steps={[
          {
            title: "View Job Details",
            description: "Click here to view the details of the job",
            target: () => viewButtonRef.current, // Target the "View" button
            placement: "right",
          },
        ]}
      />
      <div className="row">
        <div className="filter-container">
          <div className="filter-section ">
            <Searchbar
              inputValue={searchInput}
              setInputValue={setSearchInput}
              onSearchSubmit={handleSearchSubmit}
            />
            <StatusFilter
              onStatusChange={handleStatusChange}
              selectedStatus={selectedStatus}
            />
            <Reset onReset={handleReset} />
          </div>
          <div className="sort-section">
            <SortFilter
              onSortChange={handleSortChange}
              selectedValue={sortCriteria}
              activeTab={activeTab}
            />
          </div>
        </div>

        {/* Responsive layout */}
        <div className="filter-container-responsive">
          <div className="search-bar-section">
            <Searchbar
              inputValue={searchInput}
              setInputValue={setSearchInput}
              onSearchSubmit={handleSearchSubmit}
            />
          </div>
          <div className="responsive_filter">
            <div className="row  d-flex g-3 mb-1 align-items-baseline">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 d-flex flex-column align-items-baseline">
                    <span className="sort-by-label sort_p1">Status:</span>

                    <StatusFilter
                      onStatusChange={handleStatusChange}
                      selectedStatus={selectedStatus}
                    />
                  </div>
                  <div className="col-6 d-flex flex-column align-items-baseline">
                    <span className="sort-by-label sort_p1">Sort By:</span>
                    <SortFilter
                      onSortChange={handleSortChange}
                      selectedValue={sortCriteria}
                      activeTab={activeTab}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-options-section-responsive">
            {/* <StatusFilter
              onStatusChange={handleStatusChange}
              selectedStatus={selectedStatus}
            />
            <SortFilter
              onSortChange={handleSortChange}
              selectedValue={sortCriteria}
              activeTab={activeTab}
            /> */}
          </div>
          <div>
            <Reset onReset={handleReset} />
          </div>
        </div>
        {/* Responsive layout */}

        {loading ? (
          <SkeletonLoader excludeDetails={true} includeTable={true} />
        ) : newJobList && newJobList?.length > 0 ? (
          <Table
            className="p-0"
            columns={columns}
            dataSource={newJobList}
            pagination={{
              pageSize: 12,
              total: jobsCount,
              current: currentPage,
              onChange: handlePageChange,
              showSizeChanger: false,
            }}
          />
        ) : (
          <NoJobApplicantFound />
        )}
      </div>
    </>
  );
};

export default MyJobsTable;
