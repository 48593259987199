import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
} from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { websocketUrl } from "../../../../api/instance";
import { magic_stick } from "../../../../assets/images/images";
import GenerateWithAi from "../../../../components/CommonComponents/GenerateWithAi";
import useWebSocket from "../../../../hooks/useWebSocket";
import { fetchCurrencies } from "../../../../redux/actions/jobActions";
import { updateJobDetails } from "../../../../redux/reducers/jobReducer";
import {
  employmentType,
  getModeOfWork,
  getSeniority,
  qualifications,
} from "../../../../utils/constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Include style for React Quill
import TurndownService from "turndown";
import { marked } from "marked";
import DOMPurify, { sanitize } from "dompurify";
import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { usePricingModal } from "../../../../contexts/PricingModalContext";
import { Link } from "react-router-dom";

const quillModules = {
  toolbar: [["bold", "italic"], [{ list: "bullet" }]],
};

const quillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

const turndownService = new TurndownService();

const JobDetailsFrom2 = ({ form, setDisabledBtn }) => {
  // const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isPremium = (state) => state.company?.details?.is_premium;
  const { showPricingModal } = usePricingModal();
  const [value, setValue] = useState(15);
  const [isButtonAnimating, setIsButtonAnimating] = useState(false);
  const formRef = useRef(null);
  const [isInitialMessageSet, setIsInitialMessageSet] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const currencies = useSelector((state) => state.job.currencies);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const location = useSelector((state) => state.location?.userCountry);
  const jobDetails = useSelector((state) => state.job.details);
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const userId = user.user_id;
  const mode_of_work = getModeOfWork(jobDetails, jobDetails?.mode_of_work);
  const [jobDescription, setJobDescription] = useState("");
  const [customQuestions, setCustomQuestions] = useState([""]);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  const salary = {
    from: jobDetails?.salary_min
      ? Math.round(jobDetails.salary_min)
      : undefined,
    to: jobDetails?.salary_max ? Math.round(jobDetails.salary_max) : undefined,
    currency: jobDetails?.currency,
  };

  const seniority_level = getSeniority(jobDetails, jobDetails?.seniority_level);

  const url = `${websocketUrl}/job_descrption/${userId}/`;

  const payload = {
    job_title: jobDetails?.title || "[Job title]",
    // company_name: company?.name || "[Company name]",
    skills_required: [jobDetails?.skills],
    employment_type: employmentType[jobDetails?.employment_type] || "Unknown",
    seniority_level: seniority_level,
    // years_of_experience: years_of_experience,
    start_year: jobDetails?.experience - 1,
    end_year: jobDetails?.experience,
    number_of_vacancies: null,
    qualification_eligibility:
      qualifications[jobDetails?.qualification] || "Unknown",
    mode_of_work: mode_of_work,
    job_location: jobDetails?.location,
    salary: salary,
    benefits_and_opportunities: [jobDetails?.perks_benefits],
    responsibilities: jobDetails?.responsibilities,
  };

  const { salary: removedSalary, ...newPayloadToSend } = payload;

  const { sendData, socketLoading, combinedMessage } = useWebSocket(url);

  const isGenerateButtonDisabled = () => {
    return !jobDetails?.title;
  };

  useEffect(() => {
    setDisabledBtn(socketLoading);
  }, [socketLoading]);

  useEffect(() => {
    if (jobDetails) {
      form.setFieldsValue(jobDetails);
    }
  }, [form, jobDetails]);

  const onValuesChange = (_, allValues) => {
    // console.warn(allValues.location);
    dispatch(
      updateJobDetails({ ...allValues, custom_questions: customQuestions })
    );
    setFormChanged(true);
  };

  const handleAIGeneration = () => {
    formRef.current
      .validateFields()
      .then(() => {
        toast.dismiss();
        setIsInitialMessageSet(false);
        form.setFields([
          {
            name: "description",
            errors: [],
          },
        ]);
        setProgress(0);
        const salaryDisclosure = form.getFieldValue("disclose_salary");
        const payload = {
          job_title: jobDetails?.title || "[Job title]",
          skills_required: [jobDetails?.skills],
          employment_type:
            employmentType[jobDetails?.employment_type] || "Unknown",
          seniority_level: seniority_level,
          start_year: jobDetails?.experience - 1,
          end_year: jobDetails?.experience,
          number_of_vacancies: null,
          qualification_eligibility:
            qualifications[jobDetails?.qualification] || "Unknown",
          mode_of_work: mode_of_work,
          job_location: jobDetails?.location,
          benefits_and_opportunities: [jobDetails?.perks_benefits],
          responsibilities: jobDetails?.responsibilities,
        };
        if (!salaryDisclosure) {
          payload.salary = {
            from: jobDetails?.salary_min,
            to: jobDetails?.salary_max,
            currency: jobDetails?.currency,
          };
        }
        sendData(payload);
        let progressValue = 0;
        const interval = setInterval(() => {
          progressValue += 1;
          setProgress((prevProgress) => {
            if (prevProgress < 99) {
              return progressValue;
            } else {
              clearInterval(interval);
              return prevProgress;
            }
          });
        }, 100);
        setIsButtonAnimating(true);
        setTimeout(() => {
          setIsButtonAnimating(false);
        }, 1000);
      })
      .catch((errorInfo) => {
        console.error("Validation errors:", errorInfo);
        const nonDescriptionErrors = errorInfo.errorFields.filter(
          (field) => field.name[0] !== "description"
        );
        if (nonDescriptionErrors.length > 0) {
          const errorField = nonDescriptionErrors[0];
          const fieldElement = document.querySelector(
            `[id="${errorField.name[0]}"]`
          );
          if (fieldElement) {
            fieldElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            fieldElement.focus();
          }
        } else {
          // If the only error is related to the description field, proceed with AI generation
          toast.dismiss();
          setIsInitialMessageSet(false);
          form.setFields([
            {
              name: "description",
              errors: [],
            },
          ]);
          setProgress(0);
          const salaryDisclosure = form.getFieldValue("disclose_salary");
          const payload = {
            job_title: jobDetails?.title || "[Job title]",
            skills_required: [jobDetails?.skills],
            employment_type:
              employmentType[jobDetails?.employment_type] || "Unknown",
            seniority_level: seniority_level,
            start_year: jobDetails?.experience - 1,
            end_year: jobDetails?.experience,
            number_of_vacancies: null,
            qualification_eligibility:
              qualifications[jobDetails?.qualification] || "Unknown",
            mode_of_work: mode_of_work,
            job_location: jobDetails?.location,
            benefits_and_opportunities: [jobDetails?.perks_benefits],
            responsibilities: jobDetails?.responsibilities,
          };
          if (!salaryDisclosure) {
            payload.salary = {
              from: jobDetails?.salary_min,
              to: jobDetails?.salary_max,
              currency: jobDetails?.currency,
            };
          }
          sendData(payload);
          let progressValue = 0;
          const interval = setInterval(() => {
            progressValue += 1;
            setProgress((prevProgress) => {
              if (prevProgress < 99) {
                return progressValue;
              } else {
                clearInterval(interval);
                return prevProgress;
              }
            });
          }, 100);
          setIsButtonAnimating(true);
          setTimeout(() => {
            setIsButtonAnimating(false);
          }, 1000);
        }
      });
  };

  useEffect(() => {
    const markdown = turndownService.turndown(jobDetails.description || "");
    const sanitizedMarkdown = sanitize(markdown);
    setJobDescription(sanitizedMarkdown);
  }, [jobDetails?.description]);

  useEffect(() => {
    if (
      combinedMessage !== null &&
      combinedMessage !== "" &&
      !isInitialMessageSet
    ) {
      setProgress(100);
      form.setFields([
        {
          name: "description",
          value: "",
          errors: [],
        },
      ]);
      setTimeout(() => {
        dispatch(
          updateJobDetails({ ...jobDetails, description: combinedMessage })
        );
        setIsInitialMessageSet(true);
      }, 500);
      form.setFieldsValue({ description: combinedMessage });
    }
  }, [combinedMessage, dispatch, jobDetails, isInitialMessageSet]);

  const handleDescriptionChange = (content) => {
    const sanitizedContent = DOMPurify.sanitize(content);
    const isContentEmpty =
      sanitizedContent.replace(/<[^>]*>/g, "").trim() === "";

    setJobDescription(sanitizedContent);
    dispatch(
      updateJobDetails({
        ...jobDetails,
        description: isContentEmpty ? "" : sanitizedContent,
      })
    );
    form.validateFields(["description"]);
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchCurrencies(token));
    }
    fetchUserLocationAndSetCurrency();
  }, []);

  const mapCountryToCurrency = async (countryCode) => {
    try {
      const response = await axios.get(
        `https://restcountries.com/v3.1/alpha/${countryCode}`
      );

      const currencyCode = Object.keys(response.data[0].currencies)[0];

      return currencyCode;
    } catch (error) {
      console.error("Error fetching country information:", error);
    }
  };

  const fetchUserLocationAndSetCurrency = async () => {
    if (location !== null && currencies === null) {
      try {
        const currencyCode = await mapCountryToCurrency(location);
        const currencySymbol = currencyCode;

        setDefaultCurrency(currencySymbol);
        form.setFieldsValue({ currency: currencyCode });
      } catch (error) {
        console.error("Error fetching location-based currency", error);
      }
    }
  };

  const onFinish = (values) => {};

  const validateMaxSalary = (_, value) => {
    return new Promise((resolve, reject) => {
      if (value === undefined || value === null || value === "") {
        reject(new Error("Please fill in the maximum salary"));
      } else {
        const minSalary = form.getFieldValue("salary_min");
        if (Number(value) <= Number(minSalary)) {
          reject(new Error("Enter valid salary range."));
        } else if (!Number.isInteger(Number(value))) {
          reject(new Error("Salary should not be in decimal."));
        } else {
          resolve();
        }
      }
    });
  };

  const validateMinSalary = (_, value) => {
    return new Promise((resolve, reject) => {
      if (value === undefined || value === null || value === "") {
        reject(new Error("Please fill in the maximum salary"));
      } else {
        if (!Number.isInteger(Number(value))) {
          reject(new Error("Salary should not be in decimal."));
        } else {
          resolve();
        }
      }
    });
  };

  const convertMinutesToString = (minutes) => {
    return `00:${minutes.toString().padStart(2, "0")}:00`;
  };

  const handleIncrement = () => {
    if (value < 45) {
      const newValue = value + 5; // Calculate new value first
      const newValueString = convertMinutesToString(newValue);
      setValue(newValue); // Then set the new value to the local state
      dispatch(
        updateJobDetails({
          ...jobDetails,
          interview_duration: newValueString, // Use the calculated new value
        })
      );
    }
  };

  useEffect(() => {
    if (formChanged && jobDetails?.id && !isToastOpen) {
      toast.dismiss(); // Dismiss all existing toasts before showing a new one
      toast.warning(
        "Click 'Generate with AI' to apply changes to the job description.",
        {
          autoClose: false,
          onOpen: () => setIsToastOpen(true), // Set isToastOpen to true when the toast opens
          onClose: () => {
            setIsToastOpen(false); // Reset isToastOpen when the toast closes
            setFormChanged(false);
          },
        }
      );
    }
  }, [formChanged, jobDetails?.id]);

  const handleDecrement = () => {
    if (value > 15) {
      const newValue = Math.max(15, value - 5);
      const newValueString = convertMinutesToString(newValue); // Ensure it doesn't go below 15
      setValue(newValue); // Then set the new value to the local state
      dispatch(
        updateJobDetails({
          ...jobDetails,
          interview_duration: newValueString, // Use the calculated new value
        })
      );
    }
  };

  const handleAddQuestion = () => {
    if (customQuestions.length < 5) {
      const updatedQuestions = [...customQuestions, ""];
      setCustomQuestions(updatedQuestions);
        // Check if all questions are non-empty before adding add_ons
    const hasEmptyQuestion = updatedQuestions.some((question) => question.trim() === "");

      dispatch(
        updateJobDetails({
          ...jobDetails,
          custom_questions: updatedQuestions,
          ...(hasEmptyQuestion ? {} : { add_ons: "2" }),
        })
      );
      setIsAddButtonDisabled(
        updatedQuestions.length >= 5 || hasEmptyQuestion
          // updatedQuestions.some((question) => question.trim() === "")
      );
    } else {
      toast.warning("You can only add up to 5 questions.");
    }
  };

  const handleQuestionChange = (event, index) => {
    let { value } = event.target;
    let updatedQuestions = [...customQuestions];
    updatedQuestions[index] = value;
    setCustomQuestions(updatedQuestions);

    const hasEmptyQuestion = updatedQuestions.some((question) => question.trim() === "");

    dispatch(
      updateJobDetails({
        ...jobDetails,
        custom_questions: updatedQuestions,
        ...(hasEmptyQuestion ? {} : { add_ons: "2" }), 
      })
    );
    setIsAddButtonDisabled(
      updatedQuestions.length >= 5 ||hasEmptyQuestion
        // updatedQuestions.some((question) => question.trim() === "")
    );
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = [...customQuestions];
    updatedQuestions.splice(index, 1);
    setCustomQuestions(updatedQuestions);
    dispatch(
      updateJobDetails({ ...jobDetails, custom_questions: updatedQuestions })
    );
    setIsAddButtonDisabled(
      updatedQuestions.length >= 5 ||
        updatedQuestions.some((question) => question.trim() === "")
    );
  };

  useEffect(() => {
    if (jobDetails?.custom_questions) {
      setCustomQuestions(jobDetails.custom_questions);
      setIsAddButtonDisabled(
        jobDetails.custom_questions.length >= 5 ||
          jobDetails.custom_questions.some((question) => question.trim() === "")
      );
    }
  }, [jobDetails?.custom_questions]);

  return (
    <section className="form_sec_new_inner_first">
      <div className="row">
        <div className="col-lg-12 col-md-12 text-start">
          <div className="form_sec_new_inner_first_innwer">
            <Form
              form={form}
              ref={formRef}
              onFinish={onFinish}
              onValuesChange={onValuesChange}
              className="job_details_from"
            >
              <Form.Item
                name="qualification"
                label="Qualification/ Eligibility"
                rules={[
                  {
                    required: true,
                    message: "Please select a Qualification",
                  },
                ]}
              >
                <Select
                  id="qualification"
                  placeholder="Select eligibility"
                  className="select_industry text-left"
                >
                  <Select.Option value={1}>Unspecified</Select.Option>
                  <Select.Option value={2}>
                    High School or Equivalent
                  </Select.Option>
                  <Select.Option value={3}>Diploma</Select.Option>
                  <Select.Option value={4}>Bachelors Degree</Select.Option>
                  <Select.Option value={5}>Masters Degree</Select.Option>
                  <Select.Option value={6}>Doctorate</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="mode_of_work"
                label="Mode of work"
                rules={[
                  {
                    required: true,
                    message: "Please select a mode of work",
                  },
                ]}
              >
                <Radio.Group className="custom_radio" id="mode_of_work">
                  <Radio value={1} className="workmode_location">
                    On-site
                  </Radio>
                  <Radio value={2} className="workmode_location">
                    Remote
                  </Radio>
                  <Radio value={3} className="workmode_location">
                    Hybrid
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="location"
                label="Job Location"
                rules={[
                  {
                    required: true,
                    message: "Please enter the job location",
                  },
                ]}
              >
                {/* {console.warn(form.getFieldValue("location"))} */}
                <Autocomplete
                  id="location"
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                  onPlaceSelected={(place) => {
                    if (place && place.formatted_address) {
                      const location = place.formatted_address;
                      form.setFieldsValue({ location });
                      // Manually trigger onValuesChange after setting the field value
                      onValuesChange(null, { location: location });
                    }
                  }}
                  defaultValue={form.getFieldValue("location")}
                  onChange={(event) => {
                    form.setFieldsValue({ location: event.target.value });
                  }}
                  options={{
                    types: ["(cities)"],
                  }}
                />
              </Form.Item>
              <div className="row">
                <label>Annual Compensation:</label>
                <div className="col-sm-12 col-md-5  text-start years_exp salary_error">
                  <Form.Item
                    name="salary_min"
                    label="From"
                    rules={[
                      {
                        required: true,
                        validator: validateMinSalary,
                      },
                    ]}
                  >
                    <InputNumber
                      id="salary_min"
                      min={1}
                      placeholder="0"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
                <div className=" col-sm-12 col-md-5  text-start years_exp salary_error">
                  <Form.Item
                    name="salary_max"
                    label="To"
                    rules={[{ required: true, validator: validateMaxSalary }]}
                  >
                    <InputNumber
                      id="salary_max"
                      placeholder="0"
                      min={1}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-12 col-md-2 text-start years_exp">
                  <Form.Item
                    name="currency"
                    label="Currency"
                    rules={[
                      { required: true, message: "Please select the currency" },
                    ]}
                  >
                    <Select
                      className="CurrencyType"
                      // defaultValue={defaultCurrency}
                      placeholder="Select Currency"
                    >
                      {Object.entries(currencies).map(([key, value]) => (
                        <Select.Option
                          id="currency"
                          key={key}
                          value={key}
                        >{`${value} ${key}`}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="custom_checkbox">
                <Form.Item
                  label=""
                  name="disclose_salary"
                  valuePropName="checked"
                >
                  <div>
                    <span> Salary range as well to be included</span>
                    <Checkbox className="custom_checkbox_head"></Checkbox>
                  </div>
                </Form.Item>
                <span className="custom_text">Not Disclose</span>
              </div>
              <div className="row">
                <label>Tolerance Margin</label>
                <div className="col-9 text-start ">
                  <p>
                    Tolerance Margin allows considering candidates with
                    experience close to, but not exactly matching the
                    requirement, ensuring they are not disqualified.
                  </p>
                </div>
                <div className="col-3 text-end">
                  <Form.Item
                    label=""
                    name="tolerance_margin"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </div>
              </div>

              {/* <div className="row">
                <label>Duration</label>
                <div className="col-12 text-start years_exp star_min">
                  <Form.Item name="interview_duration" label="">
                    <div
                      className=""
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <InputNumber
                        min={15}
                        max={45}
                        value={jobDetails?.interview_duration || value}
                        key={"interview_duration"}
                        readOnly={true}
                        formatter={(value) => `${value} Minutes`}
                        onChange={setValue}
                        style={{ flexGrow: 1 }}
                      />
                      <div className="inc_dec">
                        <Button
                          onClick={handleDecrement}
                          style={{ marginLeft: 8 }}
                          disabled={value <= 15}
                        >
                          -
                        </Button>
                        <Button
                          onClick={handleIncrement}
                          style={{}}
                          disabled={value >= 45}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                    <p style={{ marginTop: "15px", marginBottom: "0px" }}>
                      Define the optimal time frame for AI interviews tailored
                      to your job profile's requirements.
                    </p>
                  </Form.Item>
                </div>
              </div> */}

              <div className="mt-3">
                <Form.Item
                  name="description"
                  label="Job description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the job overview",
                      validator: (_, value) => {
                        const sanitizedValue = DOMPurify.sanitize(value);
                        const isValueEmpty =
                          sanitizedValue.replace(/<[^>]*>/g, "").trim() === "";
                        if (isValueEmpty) {
                          return Promise.reject(
                            new Error("Please enter the job overview")
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <button
                    type="button"
                    className={`data_generator ${
                      isButtonAnimating ? "animated" : ""
                    }`}
                    onClick={handleAIGeneration}
                    disabled={isGenerateButtonDisabled()}
                  >
                    <img src={magic_stick} alt="" /> Generate with A.I
                  </button>
                  {socketLoading ? (
                    <GenerateWithAi progress={progress} />
                  ) : (
                    <div className="react-quill-container">
                      <ReactQuill
                        id="description"
                        theme="snow"
                        value={marked(jobDetails?.description || "")}
                        key={"description"}
                        onChange={handleDescriptionChange}
                        modules={quillModules}
                        formats={quillFormats}
                        placeholder="Enter Company Overview. For Example: Perks and benefits, Culture and work environment, Career development Opportunities, Community Engagement and Responsibilities, Latest News And Updates"
                        rows={4}
                      />
                    </div>
                    // <Input.TextArea
                    //   id="description"
                    //   style={{ height: 200, resize: "none", marginTop: "10px" }}
                    //   className="textarea_generator"
                    //   value={jobDetails?.description || ""}
                    //   key={"description"}
                    //   onChange={handleDescriptionChange}
                    //   placeholder="Enter Company Overview. For Example: Perks and benefits, Culture and work environment, Career development Opportunities, Community Engagement and Responsibilities, Latest News And Updates"
                    //   rows={4}
                    // />
                  )}
                </Form.Item>

                <hr style={{ marginBottom: "30px", marginTop: "30px" }} />

                <div className="company_details_sec_banner_main">
                  {!isPremium && (
                    <div className="job_banner_demo">
                      <div style={{ display: "flex", gap: "8px" }}>
                        <InfoCircleOutlined style={{ marginLeft: 5 }} />
                        <p>Important Note </p>
                      </div>
                      <hr />
                      <ul>
                        <li>
                          Custom questions allow you to tailor the interview
                          process to your specific needs. Available in premium
                          plans.{" "}
                          <span
                            style={{
                              color: "blue",
                              cursor: "pointer",
                              fontWeight: "normal",
                            }}
                            onClick={showPricingModal}
                          >
                            Upgrade to unlock.
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                <Form.Item label="Add Custom Interview Questions (Optional)">
                  <p style={{ marginBottom: "20px" }}>
                    You can personalize your interview by adding up to five
                    custom questions to complement the AI interview.
                  </p>

                  {customQuestions.map((question, index) => (
                    <div
                      key={index}
                      className="custom-question-item"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 16,
                      }}
                    >
                      <Input
                        value={question}
                        onChange={(e) => handleQuestionChange(e, index)}
                        placeholder={`Question ${
                          index + 1
                        } : Type your question here`}
                        style={{ flex: 1 }}
                      />
                      <CloseCircleOutlined
                        style={{
                          color: "black",
                          marginLeft: 8,
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteQuestion(index)}
                      />
                    </div>
                  ))}
                  <div className="add_question_button_container">
                    <Button
                      onClick={handleAddQuestion}
                      className="add_question_button"
                      disabled={isAddButtonDisabled}
                    >
                      + Add Question
                    </Button>
                  </div>
                  {!isPremium && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                          color: "white",
                          padding: "20px",
                          borderRadius: "5px",
                          textAlign: "center",
                        }}
                      >
                        Upgrade to a premium plan to add custom interview
                        questions.
                      </div>
                    </div>
                  )}
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {/* {console.warn(jobDetails.description)} */}
    </section>
  );
};

export default JobDetailsFrom2;
