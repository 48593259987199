import React, { useEffect, useLayoutEffect, useState } from "react";
import { toast } from "react-toastify";
import "./CompanyDetailOne.css";

import { Button, Form, Input, Modal, Space, Tooltip, Upload } from "antd";
import "../../../../components/CommonComponents/commonComponant.css";
import {
  DeleteOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Autocomplete from "react-google-autocomplete";
import { ThreeCircles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { baseURL, websocketUrl } from "../../../../api/instance";
import {
  company_details,
  magic_stick,
} from "../../../../assets/images/images";
import ChipsInputComponent from "../../../../components/CommonComponents/ChipsInput";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import GenerateWithAi from "../../../../components/CommonComponents/GenerateWithAi";
import UploadButton from "../../../../components/CustomComponents/UploadButton";
import useWebSocket from "../../../../hooks/useWebSocket";
import {
  resetCompany,
  updateCompanyDetails,
} from "../../../../redux/reducers/companyReducer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Include style for React Quill
import TurndownService from "turndown";
import { marked } from "marked";
import DOMPurify from "dompurify";
import useFileUpload from "../../../../hooks/useFileUpload";
import useSuggestionsGenerator from "../../../../hooks/useAISuggestionsGenerator";

const quillModules = {
  toolbar: [["bold", "italic"]],
};

const quillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];
const turndownService = new TurndownService();

const CompanyDetailOne = ({
  setCurrentStep,
  editedContent,
  setEditedContent,
}) => {
  const token = useSelector((state) => state?.auth?.token);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { uploadFile } = useFileUpload();
  const [imageUrl, setImageUrl] = useState();
  const [previewVisible, setPreviewVisible] = useState(false);
  const companyDetails = useSelector((state) => state?.company?.details);
  const registeredCompanyName = useSelector(
    (state) => state?.auth.user.registered_company_name
  );
  const registeredIndustryType = useSelector(
    (state) => state?.auth.user.registered_industry_name
  );
  const userId = useSelector((state) => state.auth?.user?.user_id);
  const url = `${websocketUrl}/company_profile_descrption/${userId}/`;
  const payload = {
    company_name: companyDetails?.name,
    company_address: companyDetails?.address,
    industry_type: companyDetails?.industry,
    products_and_services: companyDetails?.product_services,
    perks_and_benefits: companyDetails?.perks_benefits,
    culture_and_work_environment: companyDetails?.culture,
    current_profile: "",
  };
  const { sendData, socketLoading, combinedMessage } = useWebSocket(url);

  const [localOverview, setLocalOverview] = useState(
    companyDetails?.overview || ""
  );

  const aiIndustryTypes = useSuggestionsGenerator({
    type: "industry", keyword: companyDetails?.industry ?? registeredIndustryType, previousSuggestions: Array.isArray(companyDetails?.industry)
      ? companyDetails.industry.join(",")
      : companyDetails?.industry || "", jobProfile: "", experience: ""
  });

  const aiProducServices = useSuggestionsGenerator({
    type: "product & services", keyword: companyDetails?.industry ?? registeredIndustryType, previousSuggestions: Array.isArray(companyDetails?.product_services)
      ? companyDetails.product_services.join(",")
      : companyDetails?.product_services || "", jobProfile: "", experience: ""
  });

  const aiPerksBenefits = useSuggestionsGenerator({
    type: "perks & benefits", keyword: companyDetails?.industry ?? registeredIndustryType, previousSuggestions: Array.isArray(companyDetails?.perks_benefits)
      ? companyDetails.perks_benefits.join(",")
      : companyDetails?.perks_benefits || "", jobProfile: "", experience: ""
  });

  const aiCultureEnvironment = useSuggestionsGenerator({
    type: "culture & environment", keyword: companyDetails?.industry ?? registeredIndustryType, previousSuggestions: Array.isArray(companyDetails?.culture)
      ? companyDetails.culture.join(",")
      : companyDetails?.culture || "", jobProfile: "", experience: ""
  });

  const computeArrayFromField = (fieldValue) => {
    let resultArray = [];

    if (typeof fieldValue === "string") {
      resultArray = fieldValue.split(",");
    } else if (typeof fieldValue === "object" && fieldValue !== null) {
      resultArray = Object.values(fieldValue);
    }

    return resultArray;
  };
  const [selectedIndustry, setSelectedIndustry] = useState(
    computeArrayFromField(companyDetails?.industry) ?? []
  );
  const [selectedProduct, setSelectedProduct] = useState(
    computeArrayFromField(companyDetails?.product_services) ?? []
  );
  const [selectedPerks, setSelectedPerks] = useState(
    computeArrayFromField(companyDetails?.perks_benefits) ?? []
  );
  const [selectedCulture, setSelectedCulture] = useState(
    computeArrayFromField(companyDetails?.culture) ?? []
  );

  const addSuggestedItem = (
    selectedItems,
    itemToAdd,
    setSelectedItems,
    handleChange
  ) => {
    if (!selectedItems.includes(itemToAdd)) {
      const updatedItems = [...selectedItems, itemToAdd];
      handleChange(updatedItems);
      setSelectedItems(updatedItems);
    } else {
      toast.error("Chip is already selected.");
    }
  };

  const cleanCompanyDetails = () => {
    Modal.confirm({
      title: "Are you sure you want to go back?",
      content:
        "If you go back now, you will lose any unsaved data. Do you want to proceed?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch(resetCompany());
        navigate("/dashboard");
      },
      onCancel: () => { },
    });
  };

  const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleIndustryTypeChange = (newChips) => {
    // Ensure the registeredIndustryType is retained if it's not null or undefined
    const updatedChips = registeredIndustryType
      ? newChips.includes(registeredIndustryType)
        ? newChips
        : [...newChips, registeredIndustryType]
      : newChips;
  
    setSelectedIndustry(updatedChips);
    dispatch(
      updateCompanyDetails({ ...companyDetails, industry: updatedChips.join(",") })
    );
    form.validateFields(["industry"]);
  };  

  const handleProductandServiceChange = (newChips) => {
    setSelectedProduct(newChips);
    dispatch(
      updateCompanyDetails({
        ...companyDetails,
        product_services: newChips.join(","),
      })
    );
    form.validateFields(["product_services"]);
  };

  const handlePerksAndBenefitsChange = (newChips) => {
    setSelectedPerks(newChips);
    dispatch(
      updateCompanyDetails({
        ...companyDetails,
        perks_benefits: newChips.join(","),
      })
    );
    form.validateFields(["perks_benefits"]);
  };

  const handleCultureAndWorkChange = (newChips) => {
    setSelectedCulture(newChips);
    dispatch(
      updateCompanyDetails({ ...companyDetails, culture: newChips.join(",") })
    );
    form.validateFields(["culture"]);
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleAddressChange = (place) => {
    const newAddress = place?.formatted_address;
    dispatch(updateCompanyDetails({ ...companyDetails, address: newAddress }));
    form.validateFields(["address"]);
  };

  const handleOverviewChange = (content, delta, source, editor) => {
    setLocalOverview(content);
    setEditedContent(editor.getHTML());
    form.setFieldsValue({
      overview: editor.getHTML(),
    });
  };

  const getPlainText = (html) => {
    const cleanHtml = DOMPurify.sanitize(html);
    const doc = new DOMParser().parseFromString(cleanHtml, "text/html");
    return doc.body.textContent || "";
  };

  const handleAIGeneration = () => {
    form
      .validateFields([
        "name",
        "address",
        "industry",
        "product_services",
        "perks_benefits",
        "culture",
      ])
      .then(() => {
        // All validations passed, proceed with A.I. generation
        setProgress(0);
        sendData(payload);
        let progressValue = 0;
        const interval = setInterval(() => {
          progressValue += 1;
          setProgress((prevProgress) => {
            if (prevProgress < 99) {
              return progressValue;
            } else {
              clearInterval(interval);
              return prevProgress;
            }
          });
        }, 100);
      })
      .catch((errorInfo) => {
        // Validation failed
        scrollToFirstError(errorInfo.errorFields);
      });
  };
  function scrollToFirstError(errorFields) {
    if (errorFields && errorFields.length > 0) {
      setTimeout(() => {
        const firstFieldWithError = document.getElementById(
          errorFields[0].name
        );
        if (firstFieldWithError) {
          firstFieldWithError.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 100);
    }
  }

  useEffect(() => {
    setImageUrl(companyDetails?.profile_pic);
  }, [companyDetails?.profile_pic]);

  const handleChange = async (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      const file = info.file.originFileObj;

      const fileUrl = await uploadFile(file, token);

      if (fileUrl) {
        toast.success("Profile photo uploaded successfully");

        // Store the uploaded file URL in Redux state
        dispatch(
          updateCompanyDetails({ ...companyDetails, profile_pic: fileUrl })
        );
        setImageUrl(fileUrl);
      } else {
        setImageUrl(
          "https://haire.s3.ap-south-1.amazonaws.com/production/2871791_7015+1.png"
        );
        toast.error("Error uploading the file");
      }
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error("Upload failed: An error occurred");
      setImageUrl(
        "https://haire.s3.ap-south-1.amazonaws.com/production/2871791_7015+1.png"
      );
    }
  };
  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = () => setPreviewVisible(true);
  const handleRemove = () =>
    setImageUrl(null) ||
    dispatch(updateCompanyDetails({ ...companyDetails, profile_pic: null }));

  const handleFormChange = (_, allValues) => {
    dispatch(updateCompanyDetails(allValues));
  };

  const handleFormSubmit = async () => {
    try {
      await form.validateFields();
      dispatch(
        updateCompanyDetails({
          ...companyDetails,
          overview: turndownService.turndown(localOverview),
          profile_pic: imageUrl,
        })
      );
      setCurrentStep(2);
    } catch (error) {
      scrollToFirstError(error.errorFields);
      toast.error("Please fill in the company details correctly");
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: registeredCompanyName || companyDetails?.name || "",
      industry: registeredIndustryType || companyDetails?.industry || "",
      ...companyDetails,
    });
  }, [form, companyDetails, registeredCompanyName, registeredIndustryType]);

  useEffect(() => {
    if (editedContent) {
      const htmlContent = marked(editedContent); // Convert markdown to HTML
      setEditedContent(htmlContent);
    }
  }, [editedContent]);

  useLayoutEffect(() => {
    if (combinedMessage !== null && combinedMessage !== "") {
      setProgress(100);
      setTimeout(() => {
        setLocalOverview(combinedMessage);
        setEditedContent(combinedMessage);
        form.setFieldsValue({
          overview: combinedMessage,
        });
      }, 500);
    }
  }, [combinedMessage, dispatch, companyDetails]);

  const backToDashboard = () => {
    navigate("/dashboard/my-company");
  };

  if (companyDetails?.id) {
    return (
      <>
        <section className="container">
          <div className="maintainance_main">
            <img src={company_details} alt="page not found" />
            <h3>Company Profile Already Created.</h3>
            <p style={{ width: "705px" }}>
              Welcome back! It looks like your company profile has already been
              set up on our
              <br /> platform. You can now dive straight into managing your
              recruitment activities.
            </p>
            <button className="Pagenotfoundbackbtn" onClick={backToDashboard}>
              Go to My Company
            </button>
          </div>
        </section>
      </>
    );
  }
  return (
    <>
      <header className="text-center mtb">
        {location?.state?.isEmpty ? (
          <div className="Company_details_sec_new_head">
            <hr className="spacer20px" />
            <div className="container comp_det_shik_head">
              <DashboardHeader
                title=""
                backButtonLink={cleanCompanyDetails}
                backButtonText="Back to Dashboard"
                className="mrg-top"
                style={{ marginTop: "80px" }}
              />
              <h4>You're almost there!</h4>
              <p>
                Provide your company information to start posting jobs
                effortlessly.
              </p>
            </div>
          </div>
        ) : (
          <div className="container top_padding">
            <hr className="spacer20px" />
            <DashboardHeader
              title=""
              backButtonLink={cleanCompanyDetails}
              backButtonText="Back to Dashboard"
              className="mrg-top"
            // style={{ marginTop: "80px" }}
            />
            <h3 className="company_detail_heading">Company Details</h3>
            <p className="company_detail_subheading">
              Complete all necessary company details to create a complete and
              compelling profile.
            </p>
          </div>
        )}
      </header>
      <section className="Company_details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="company_detials_wrapper">
                <Form
                  onKeyDown={handleFormKeyDown}
                  form={form}
                  onValuesChange={handleFormChange}
                  name="validateOnly"
                  layout="vertical"
                  autoComplete="off"
                  className="company_detail_form"
                >
                  <Form.Item
                    name="profile_pic"
                    valuePropName="fileList"
                    rules={[
                      {
                        required: true,
                        message: "Please add company logo",
                      },
                    ]}
                  >
                    <div id="profile_pic">
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={`${baseURL}job/mock-api/`}
                        beforeUpload={beforeUpload}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        accept="image/jpeg, image/png"
                      >
                        {loading ? (
                          <ThreeCircles
                            height="20"
                            width="20"
                            color="#1865C1"
                            ariaLabel="loading-indicator"
                          />
                        ) : imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                              padding: "10px",
                            }}
                          />
                        ) : (
                          <UploadButton />
                        )}
                      </Upload>
                    </div>
                    {(imageUrl || companyDetails?.profile_pic) && (
                      <div className="view_delete">
                        <Button
                          icon={<EyeOutlined />}
                          onClick={handlePreview}
                        ></Button>
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={handleRemove}
                        ></Button>
                      </div>
                    )}
                    <Modal
                      open={previewVisible}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="Preview"
                        style={{ width: "100%" }}
                        src={imageUrl || companyDetails?.profile_pic}
                      />
                    </Modal>

                    <span className="profile_pic">
                      Add Company Logo <span style={{ color: "red" }}>*</span>
                    </span>
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label="Company Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter company name",
                        trigger: "blur",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Company Name" id="name" />
                  </Form.Item>
                  <Form.Item
                    name="address"
                    label="Company Address"
                    rules={[
                      {
                        required: true,
                        message: "Please enter company address",
                      },
                    ]}
                  >
                    <Autocomplete
                      id="address"
                      style={{ width: "100%" }}
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                      onPlaceSelected={handleAddressChange}
                      defaultValue={form.getFieldValue("address")}
                      onChange={(event) => {
                        form.setFieldsValue({ address: event.target.value });
                      }}
                      options={{
                        types: ["establishment"],
                      }}
                      inputProps={{
                        autoComplete: "off",
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="industry"
                    label={
                      <span>
                        <Tooltip
                          title="Limit: 75 character per chip. Sentences will split at commas.
"
                        >
                          <Button type="text" icon={<InfoCircleOutlined />} />
                        </Tooltip>
                        Industry Type{" "}
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please add at least one industry type",
                      },
                    ]}
                  >
                    <ChipsInputComponent
                      id={"industry"}
                      key="industry"
                      value={companyDetails?.industry}
                      placeholder="Type a keyword and press enter"
                      onChipsChange={handleIndustryTypeChange}
                      formatCase="camelCase"
                    />
                  </Form.Item>
                  <div style={{ paddingBottom: "30px" }}>
                    <div className="suggestion_sec_new">
                      <ul className="list_item_sec">
                        {aiIndustryTypes?.map((industry, index) => (
                          <li
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() =>
                              addSuggestedItem(
                                selectedIndustry,
                                industry,
                                setSelectedIndustry,
                                handleIndustryTypeChange
                              )
                            }
                          >
                            {industry}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <Form.Item
                    name="product_services"
                    label={
                      <span>
                        <Tooltip
                          title="Limit: 75 character per chip. Sentences will split at commas.
"
                        >
                          <Button type="text" icon={<InfoCircleOutlined />} />
                        </Tooltip>
                        Product And Services{" "}
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter product and services",
                      },
                    ]}
                  >
                    <ChipsInputComponent
                      id={"product_services"}
                      key="product_services"
                      value={companyDetails?.product_services}
                      placeholder="Enter Product and services"
                      onChipsChange={handleProductandServiceChange}
                      formatCase="camelCase"
                    />
                  </Form.Item>
                  <div style={{ paddingBottom: "30px" }}>
                    <div className="suggestion_sec_new">
                      <ul className="list_item_sec">
                        {aiProducServices?.map((product_services, index) => (
                          <li
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() =>
                              addSuggestedItem(
                                selectedProduct,
                                product_services,
                                setSelectedProduct,
                                handleProductandServiceChange
                              )
                            }
                          >
                            {product_services}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <Form.Item
                    name="perks_benefits"
                    label={
                      <span>
                        <Tooltip title="Limit: 75 character per chip. Sentences will split at commas.">
                          <Button type="text" icon={<InfoCircleOutlined />} />
                        </Tooltip>
                        Perks and Benefit{" "}
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Perks and benefits",
                      },
                    ]}
                  >
                    <ChipsInputComponent
                      id={"perks_benefits"}
                      key="perks_benefits"
                      value={companyDetails?.perks_benefits}
                      placeholder="Type a keyword and press enter"
                      onChipsChange={handlePerksAndBenefitsChange}
                      formatCase="camelCase"
                    />
                  </Form.Item>
                  <div style={{ paddingBottom: "30px" }}>
                    <div className="suggestion_sec_new">
                      <ul className="list_item_sec">
                        {aiPerksBenefits?.map((perks_benefits, index) => (
                          <li
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() =>
                              addSuggestedItem(
                                selectedPerks,
                                perks_benefits,
                                setSelectedPerks,
                                handlePerksAndBenefitsChange
                              )
                            }
                          >
                            {perks_benefits}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <Form.Item
                    name="culture"
                    label={
                      <span>
                        <Tooltip title="Limit: 75 character per chip. Sentences will split at commas.">
                          <Button type="text" icon={<InfoCircleOutlined />} />
                        </Tooltip>
                        Culture and Work Environment{" "}
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Culture and Work Environment",
                      },
                    ]}
                  >
                    <ChipsInputComponent
                      id={"culture"}
                      key="culture"
                      value={companyDetails?.culture}
                      placeholder="Type a keyword and press enter"
                      onChipsChange={handleCultureAndWorkChange}
                      formatCase="camelCase"
                    />
                  </Form.Item>
                  <div style={{ paddingBottom: "30px" }}>
                    <div className="suggestion_sec_new">
                      <ul className="list_item_sec">
                        {aiCultureEnvironment?.map((culture, index) => (
                          <li
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() =>
                              addSuggestedItem(
                                selectedCulture,
                                culture,
                                setSelectedCulture,
                                handleCultureAndWorkChange
                              )
                            }
                          >
                            {culture}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <Form.Item
                    name="overview"
                    label="Company Overview"
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          const plainTextValue = getPlainText(value).trim();
                          if (!plainTextValue) {
                            return Promise.reject(
                              new Error("Overview cannot be empty")
                            );
                          }
                          if (plainTextValue.length > 1000) {
                            return Promise.reject(
                              new Error(
                                "Overview cannot exceed 1000 characters"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <button
                      type="button"
                      className="data_generator"
                      onClick={handleAIGeneration}
                    >
                      <img src={magic_stick} alt="" /> Generate with A.I
                    </button>
                    {socketLoading ? (
                      <GenerateWithAi progress={progress} />
                    ) : (
                      <>
                        <div className="react-quill-container">
                          <ReactQuill
                            id="overview"
                            theme="snow"
                            value={editedContent}
                            key={"overview"}
                            onChange={handleOverviewChange}
                            modules={quillModules}
                            formats={quillFormats}
                            placeholder="Enter Company Overview.
                      For Example:
                        • Perks and benefits 
                        • Culture and work environment
                        • Career development Opportunities 
                        • Community Engagement and Responsibilities
                        • Latest News And Updates "
                            rows={4}
                          />
                        </div>
                        <div style={{ marginTop: "5px", textAlign: "right" }}>
                          {getPlainText(localOverview).length}/1000
                        </div>
                      </>
                    )}
                  </Form.Item>
                </Form>
              </div>
              <div className="new_sub_cont ">
                <Space>
                  <SubmitButton
                    form={form}
                    setCurrentStep={setCurrentStep}
                    handleFormSubmit={handleFormSubmit}
                  />
                </Space>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyDetailOne;

const SubmitButton = ({ handleFormSubmit }) => {
  return (
    <button className="saveContinue margin_bottom_1" onClick={handleFormSubmit}>
      Save & Continue
    </button>
  );
};

function resizeImage(file, maxWidth, maxHeight, callback) {
  const reader = new FileReader();
  reader.onload = (event) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");

      // Check if the original file is PNG to potentially preserve transparency
      if (file.type === "image/png") {
        ctx.fillStyle = "transparent";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }

      ctx.drawImage(img, 0, 0, width, height);

      const outputFormat =
        file.type === "image/png" ? "image/png" : "image/jpeg";
      // Use the correct MIME type for the blob, matching the original file type
      ctx.canvas.toBlob(
        (blob) => {
          const resizedFile = new File([blob], file.name, {
            type: outputFormat,
            lastModified: Date.now(),
          });
          callback(resizedFile);
        },
        outputFormat, // Correct MIME type for output
        outputFormat === "image/png" ? 1.0 : 0.7 // Adjust quality as needed
      );
    };
    img.src = event.target.result;
  };
  reader.readAsDataURL(file);
}

const beforeUpload = (file) => {
  const isImage = file.type.startsWith("image/");
  if (!isImage) {
    toast.error("You can only upload image files!");
    return false; // Stop the upload
  }

  const isLessThan10MB = file.size / 1024 / 1024 < 10; // Change the size limit as needed
  if (!isLessThan10MB) {
    toast.error("Image must be smaller than 10MB!"); // Customize the error message
    return false; // Stop the upload
  }
  return new Promise((resolve, reject) => {
    resizeImage(file, 1024, 1024, (resizedFile) => {
      resolve(resizedFile);
    });
  });
};
