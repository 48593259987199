import { DeleteOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Skeleton, Upload, message } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  Document_1,
  info,
  profile_pic_bg,
  sum_img,
} from "../../../../assets/images/images";
import UploadButton from "../../../../components/CustomComponents/UploadButton";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
import "../Applicantprofile/Applicantprofile.css";
import { baseURL } from "../../../../api/instance";
import { ThreeCircles } from "react-loader-spinner";
import LoaderApply from "../../../../components/CommonComponents/Loader/LoaderApply";
import {
  fetchJobSeekerDetails,
  getPdfToJSON,
  updateJobSeeker,
} from "../../../../redux/actions/jobSeekerActions";
import useS3Uploader from "../../../../hooks/useS3Uploader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createJobSeeker } from "../../../../redux/actions/jobSeekerActions";
import PostJobModal from "../../RecruiterDashboard/CommonRecruiterComponant/PostJobModal";
import { Document, Page } from "react-pdf";
import { marked } from "marked";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import useFileUpload from "../../../../hooks/useFileUpload";

const Applicantprofile = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileModal, setShowFileModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [fileInputValue, setFileInputValue] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [resumeFileName, setResumeFileName] = useState(null);
  const resumeJson = useSelector((state) => state?.auth?.user?.resume_json);
  const [enhancedData, setEnhancedData] = useState([]);
  const [selectedGender, setSelectedGender] = useState(null);
  const [resumeFileUrl, setResumeFileUrl] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [localFormData, setLocalFormData] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [pageNumber, setPageNumber] = useState(1); // Set to display the first page
  const pdfPreviewRef = useRef(null);
  const fileInputRef = useRef(null);
  const user = useSelector((state) => state?.auth?.user);
  const token = useSelector((state) => state.auth?.token);
  const dispatch = useDispatch();
  // const { handleUpload } = useS3Uploader();
  const { uploadStatus, uploadFile } = useFileUpload();

  const [form] = Form.useForm();

  const noticePeriodOptions = [
    { label: "15 Days or less", value: 1 },
    { label: "1 Month", value: 2 },
    { label: "2 Months", value: 3 },
    { label: "3 Months", value: 4 },
    { label: "More than 3 Months", value: 5 },
    { label: "Serving Resignation Period", value: 6 },
    { label: "Immediately", value: 7 },
  ];

  const gender = [
    { label: "Male", value: 1 },
    { label: "Female", value: 2 },
    { label: "Other", value: 3 },
  ];

  const currencyOptions = [
    { label: "$", value: "USD" },
    { label: "₹", value: "INR" },
    { label: "د.إ", value: "AED" },
  ];

  useEffect(() => {
    if (!user && token) {
      dispatch(fetchJobSeekerDetails(token, user?.id));
    }
  }, [user, token, dispatch]);

  useEffect(() => {
    if (token && user && !resumeJson) {
      dispatch(fetchJobSeekerDetails(token, user.id));
    }
  }, [token, user, dispatch, resumeJson]);

  useEffect(() => {
    if (resumeJson && resumeJson.sections) {
      const processedSections = resumeJson.sections.map((section) => ({
        ...section,
        processedContent: marked(section.content || ""),
      }));
      setEnhancedData(processedSections);
    }
  }, [resumeJson]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
    const timer = setTimeout(() => {
      setShowSkeleton(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isEditModalOpen && user) {
      setIsButtonDisabled(false);
      const currencyCode = currencyOptions.find(
        (g) => g.label === user.currency
      )?.value;
      const genderValue = gender.filter((v) => v.label == user?.gender);
      form.setFieldsValue({
        profile_pic: imageUrl,
        resume_json: user?.resume_json?.resume,
        full_name: user.full_name,
        designation: user.designation || "",
        gender:
          genderValue.length > 0 && genderValue[0].label === "UnSpecified"
            ? undefined
            : genderValue[0]?.value,
        contact: user.phone,
        current_ctc: user.current_ctc,
        currency: currencyCode,
        notice_period: user.notice_period,
        physically_challenged: user.physically_challenged,
      });
      setImageUrl(user.profile_pic);
      setResumeFileUrl(user.resume);
      setResumeFileName(user?.resume_json?.full_name);
    }
  }, [user, isEditModalOpen, form]);

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleChange = (value) => {
    setSelectedGender(value);
  };

  function scrollToFirstError(errorFields) {
    if (errorFields && errorFields.length > 0) {
      const firstErrorField = errorFields[0];
      const fieldName = Array.isArray(firstErrorField.name)
        ? firstErrorField.name.join("_")
        : firstErrorField.name;
      const element = document.getElementById(fieldName);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else {
        console.error("No element found with ID:", fieldName);
      }
    }
  }

  const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleFormChange = (_, allValues) => {
    setLocalFormData(allValues);
  };

  const handleFileChange = async (event, onlyJSON = false) => {
    const file = event.target.files[0];
    const fileSize = file.size;
    const minSize = 1000; // Minimum file size: 1 KB (in bytes)
    const maxSize = 5242880; // Maximum file size: 5 MB (in bytes)

    if (fileSize < minSize || fileSize > maxSize) {
      let errorMessage = "";
      if (fileSize < minSize) {
        errorMessage = `File size must be above 1 KB. Current file size is ${(
          fileSize / 1024
        ).toFixed(2)} KB.`;
      } else {
        errorMessage = `File size must be below 5 MB. Current file size is ${(
          fileSize / 1048576
        ).toFixed(2)} MB.`;
      }
      toast.error(errorMessage);
      return;
    }
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // MIME type for .docx
      "application/msword", // MIME type for .doc
    ];

    if (file && allowedTypes.includes(file.type)) {
      setSelectedFile(file);
      setShowFileModal(true);

      const fileUrl = await uploadFile(file, token);
      // const fileUrl = uploadResults.get(file) || uploadResults[file];

      if (fileUrl) {
        setSelectedFileType(file.type);
        setShowFileModal(true);
        setPdfUrl(fileUrl);
        try {
          let fileExtension = file.name.split(".").pop();
          if (
            file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            fileExtension = "docx";
          } else if (file.type === "application/doc") {
            fileExtension = "doc";
          } else if (file.type === "application/pdf") {
            fileExtension = "pdf";
          } else if (file.type === "image/jpeg" || file.type === "image/jpg") {
            fileExtension = "jpg";
          } else if (file.type === "image/png") {
            fileExtension = "png";
          }

          const resumeFileNameWithExtension = `${user?.full_name.replace(
            " ",
            "_"
          )}_resume.${fileExtension}`;
          if (onlyJSON) {
            await dispatch(getPdfToJSON(token, fileUrl, ["English", "Hindi"]));
          } else {
            await dispatch(
              createJobSeeker(token, fileUrl, ["English", "Hindi"])
            );
          }
          if (
            file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            toast.success("Your resume is uploaded successfully");
          } else {
            toast.success(
              `${file.type
                .split("/")[1]
                .toUpperCase()} file selected and uploaded successfully`
            );
          }
          setShowFileModal(false);
          navigate("/update-applicant-resume", { replace: true });
          setFileInputValue("");
          setResumeFileName(resumeFileNameWithExtension);
        } catch (error) {
          setShowFileModal(false);
          toast.error("Please reupload your resume.");
        }
      }
    } else {
      toast.error("Only PDF, DOCX, DOC, JPEG, JPG, and PNG files are allowed");
    }
  };

  useEffect(() => {
    setPdfUrl(selectedFile);
  }, []);

  const handleResumeChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      try {
        const fileUrl = await handleFileChange(event, true);

        if (fileUrl) {
          // Only proceed if a file URL is returned
          setResumeFileUrl(fileUrl);
          let fileExtension = file.name.split(".").pop();
          if (
            file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            fileExtension = "docx";
          } else if (file.type === "application/doc") {
            fileExtension = "doc";
          } else if (file.type === "application/pdf") {
            fileExtension = "pdf";
          } else if (file.type === "image/jpeg" || file.type === "image/jpg") {
            fileExtension = "jpg";
          } else if (file.type === "image/png") {
            fileExtension = "png";
          }

          const resumeFileNameWithExtension = `${user?.full_name.replace(
            " ",
            "_"
          )}_resume.${fileExtension}`;
          setResumeFileName(resumeFileNameWithExtension);
          const updatedResumeJson = {
            ...user.resume_json,
            resume: fileUrl,
            resume_name: file.name,
          };
          // await dispatch(
          //   updateJobSeeker(
          //     token,
          //     user.id,
          //     user.resume,
          //     updatedResumeJson,
          //     imageUrl,
          //     localFormData
          //   )
          // );

          message.success("Resume uploaded successfully.");
          navigate("/update-applicant-resume", { replace: true });
        }
      } catch (error) {
        message.error("Failed to upload resume: " + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUploadChange = async (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      const file = info.file.originFileObj;

      const fileUrl = await uploadFile(file, token);
      // const fileUrl = uploadResults.get(file) || uploadResults[file];

      if (fileUrl) {
        toast.success("Profile photo uploaded successfully");
        setImageUrl(fileUrl);
        form.setFields([
          {
            name: "profile_pic",
            errors: [],
          },
        ]);
      } else {
        setImageUrl(
          "https://haire.s3.ap-south-1.amazonaws.com/production/2871791_7015+1.png"
        );
        toast.error("Error uploading the file");
      }
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error("Upload failed: An error occurred");
      setImageUrl(
        "https://haire.s3.ap-south-1.amazonaws.com/production/2871791_7015+1.png"
      );
    }
  };

  const handlePreview = () => {
    setPreviewVisible(true);
  };

  const handleRemove = () => {
    setImageUrl(null);
    form.setFieldsValue({ profile_pic: null });
    setLocalFormData({ ...localFormData, profile_pic: null });
    form.setFields([
      {
        name: "profile_pic",
        errors: ["Please add profile photo"],
      },
    ]);
    setLoading(false);
  };

  const handleSaveAndContinue = (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then((values) => {
        const { dob, resume_json, ...restOfUser } = user;

        const updatedUser = {
          ...restOfUser,
          ...values,
          profile_pic:
            imageUrl ||
            "https://haire.s3.ap-south-1.amazonaws.com/development/Group.png",
          designation: values.designation || "",
        };
        setLoading(true);
        setIsButtonDisabled(true);
        dispatch(
          updateJobSeeker(
            token,
            user.id,
            user.resume,
            resumeJson,
            imageUrl ||
              "https://haire.s3.ap-south-1.amazonaws.com/development/Group.png",
            updatedUser
          )
        )
          .then(() => {
            toast.success("Profile updated successfully!");
            setIsEditModalOpen(false);
            dispatch(fetchJobSeekerDetails(token, user.id));
          })
          .catch((error) => {
            scrollToFirstError(error.errorFields);
            toast.error("Please fill in the profile details correctly");
            setIsButtonDisabled(false);
          })
          .finally(() => setLoading(false));
      })
      .catch((errorInfo) => {
        console.error("Error in form validation:", errorInfo);
        scrollToFirstError(errorInfo.errorFields);
      });
  };

  const handleBackButtonClick = () => {
    navigate("/applicant-dashboard");
  };

  const enhancedSections = useMemo(() => {
    if (enhancedData?.sections) {
      return enhancedData.sections.map((section) => ({
        ...section,
        processedContent: marked(section.content ?? ""),
      }));
    }
    return [];
  }, [enhancedData]);

  return (
    <>
      <Navbar />

      <section className="Myprofile_mt">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <DashboardHeader
                title=""
                backButtonLink={handleBackButtonClick}
                backButtonText="Back to Jobs Page"
              />
              <div className="applicant_profile_left_section">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="pageHeading">My Profile</h2>
                </div>
                <div className="personal_information_section mt-0">
                  <div className="applicant_profile_left_section_inner">
                    <div className="application_image_name">
                      <div className="applicant_profile_image">
                        <img
                          src={
                            user?.profile_pic ||
                            "https://haire.s3.ap-south-1.amazonaws.com/development/Group.png"
                          }
                          alt="Your Image"
                          className="img-fluid"
                          width="500"
                          height="300"
                        />
                      </div>
                      <div className="">
                        <div className="text-block profile_description">
                          <h4>{user?.full_name}</h4>
                          <p>{user?.designation || "Job Profile"}</p>
                          <p>{user?.email}</p>
                          <p>{user?.phone}</p>
                          <div className="mb-4">
                            <button
                              className="edit_profile_btn float-start"
                              onClick={toggleEditModal}
                            >
                              <i className="fas fa-pencil-alt"></i>Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div className="d-flex align-items-baseline">
                        <div>
                          <a
                            href={user?.resume}
                            download={user?.resume}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="pdf_line"
                          >
                            <p className="pdf_downloader">
                              <img src={Document_1} alt="" />
                              {(
                                resumeFileName ||
                                (user?.resume_json?.resume_name
                                  ? user.resume_json.resume_name
                                  : `${decodeURIComponent(
                                      user?.resume?.substring(
                                        user?.resume.lastIndexOf("/") + 1
                                      )
                                    )
                                      .replace(/^\d+-/, "")
                                      .substring(0, 25)}`)
                              ).substring(0, 25)}
                              <i
                                className="fas fa-download"
                                style={{ marginLeft: "5px" }}
                              ></i>
                            </p>
                          </a>
                        </div>
                        <div></div>
                      </div>
                      <button
                        onClick={() => fileInputRef.current.click()}
                        className="edit_profile_btn edit_profile_btn-width "
                      >
                        <UploadOutlined />
                        <span>Re-Upload Resume</span>
                      </button>
                      <input
                        id="resume-upload"
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleResumeChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="personal_information_section">
                    {showSkeleton ? (
                      <Skeleton active paragraph={{ rows: 4 }} />
                    ) : (
                      enhancedData.map((info, index) => (
                        <div className="confim_info_single" key={index}>
                          <div className="confim_info_single_head">
                            <h5>
                              {info.heading.charAt(0).toUpperCase() +
                                info.heading.slice(1)}
                            </h5>
                          </div>
                          <div
                            className="confim_info_single_body"
                            dangerouslySetInnerHTML={{
                              __html: info.processedContent,
                            }}
                          ></div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </section>

      {/*-------------------------------------------- Edit Form------------------------------------- */}
      <Modal
        show={isEditModalOpen}
        onHide={toggleEditModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom_modal_container"
        backdrop="static" // Prevents closing by clicking on the backdrop
        keyboard={false}
      >
        <Modal.Header closeButton className="p-2 ps-3 pe-3">
          <Modal.Title className="p-0 more_details">More Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="">
            <Form
              onKeyDown={handleFormKeyDown}
              form={form}
              name="validateOnly"
              onValuesChange={handleFormChange}
              layout="vertical"
              autoComplete="off"
              className="company_detail_form ProfileForm"
            >
              <Form.Item name="profile_pic" valuePropName="fileList">
                <div id="profile_pic">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={`${baseURL}job/mock-api/`}
                    beforeUpload={beforeUpload}
                    onPreview={handlePreview}
                    onChange={handleUploadChange}
                    accept="image/jpeg, image/png"
                  >
                    {imageUrl ? (
                      loading ? (
                        <ThreeCircles
                          height="20"
                          width="20"
                          color="#1865C1"
                          ariaLabel="loading-indicator"
                        />
                      ) : (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "contain",
                            padding: "10px",
                          }}
                        />
                      )
                    ) : loading ? (
                      <ThreeCircles
                        height="20"
                        width="20"
                        color="#1865C1"
                        ariaLabel="loading-indicator"
                      />
                    ) : (
                      <UploadButton />
                    )}
                  </Upload>
                </div>
                {imageUrl && (
                  <div className="view_delete">
                    <Button
                      icon={<EyeOutlined />}
                      onClick={handlePreview}
                    ></Button>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={handleRemove}
                    ></Button>
                  </div>
                )}
                <Modal
                  show={previewVisible}
                  onHide={() => setPreviewVisible(false)}
                  centered
                >
                  <Modal.Header closeButton className="p-2 ps-3 pe-3">
                    <Modal.Title className="p-0 profile_image">
                      Profile Image
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <img
                      src={imageUrl}
                      alt="Preview"
                      style={{ width: "100%" }}
                    />
                  </Modal.Body>
                </Modal>

                <span className="profile_pic">Edit Profile Photo</span>
              </Form.Item>
              <div className="row">
                <div className="col-12 text-start years_exp">
                  <label className="profile_label">Full Name <span class="custom_astrix">*</span></label>
                  <Form.Item
                    name="full_name"
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject(
                              new Error("Please enter full name")
                            );
                          }
                          if (!/^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(value)) {
                            return Promise.reject(
                              new Error(
                                "Only letters and a single space allowed."
                              )
                            );
                          }
                          if (value.length > 25) {
                            return Promise.reject(
                              new Error("Full name cannot exceed 25 characters")
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Enter Full Name" id="full_name" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-4 text-start years_exp">
                  <label className="profile_label">Contact Number <span class="custom_astrix">*</span></label>
                  <Form.Item
                    name="contact"
                    rules={[
                      {
                        required: true,
                        message: "Please enter contact number",
                      },
                    ]}
                  >
                    <Input
                      id="contact"
                      placeholder="Enter Contact Number"
                      disabled
                      style={{ background: "white", color: "black" }}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-6 col-sm-4 text-start years_exp">
                  <label className="profile_label">Gender <span class="custom_astrix">*</span></label>
                  <Form.Item name="gender" id="gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select your gender", // Error message displayed if no gender is selected
                    },
                  ]}>
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder="Select your gender"
                      className="custom-notice-dropdown"
                      onChange={handleChange}
                      value={selectedGender}
                    >
                      {gender.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <label className="profile_label">Job Profile</label>
              <Form.Item name="designation">
                <Input placeholder="Enter Job Profile" id="designation" />
              </Form.Item>
              <div className="row">
                <div className="col-12">
                  <label className="main-label">
                    Current CTC <span className="custom_astrix">*</span>
                  </label>{" "}
                  <div className="row">
                    <div className="col-lg-2 col-sm-4 text-start years_exp">
                      <Form.Item
                        name="currency"
                        id="currency"
                        rules={[
                          {
                            required: true,
                            message: "Please select a currency",
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger) => trigger.parentNode}
                          placeholder="Select Currency"
                          value={form.getFieldValue("currency")}
                        >
                          {currencyOptions.map((option) => (
                            <Select.Option
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-lg-10 col-sm-6 text-start years_exp">
                      <Form.Item
                        name="current_ctc"
                        id="current_ctc"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your current CTC",
                          },
                          {
                            pattern: new RegExp(/^[0-9]{1,10}$/),
                            message:
                              "CTC must be a positive integer and up to 10 digits long",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Current CTC" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-start years_exp">
                  <label className="profile_label">
                    Notice Period <span className="custom_astrix">*</span>
                  </label>
                  <Form.Item
                    name="notice_period"
                    id="notice_period"
                    rules={[
                      {
                        required: true,
                        message: "Please select your notice period",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder="Select your notice period"
                      className="custom-notice-dropdown"
                    >
                      {noticePeriodOptions.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-start years_exp">
                  <label className="profile_label">Assistance Benefits</label>
                  <Form.Item
                    name="physically_challenged"
                    id="physically_challenged"
                    rules={[
                      {
                        required: false,
                        message: "Please enter assistance benifits",
                      },
                      {
                        max: 500,
                        message:
                          "Assistance benefits cannot exceed 500 characters",
                      },
                    ]}
                  >
                    <Input.TextArea
                      style={{
                        height: 200,
                        resize: "none",
                        marginTop: "10px",
                      }}
                      className="textarea_generator"
                      // placeholder=""
                      rows={4}
                      maxLength={501}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
            <div className="save-btn-container">
              <button
                className="saveContinue"
                onClick={handleSaveAndContinue}
                disabled={isButtonDisabled}
              >
                {loading ? <LoaderApply color="white" /> : "Save & Continue"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* --------------------------------------------Pdf Scaning---------------------------------------------- */}
      <Modal
        show={showFileModal}
        onHide={() => setShowFileModal(false)}
        size="lg"
        className="pdf_view_scan_modal scan"
        centered
      >
        <Modal.Body
          className={
            selectedFileType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? "b-gg1"
              : "b-gg fingerprint"
          }
          style={{
            padding: "20px",
          }}
        >
          <div
            ref={pdfPreviewRef}
            className="file_preview"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {pdfUrl &&
              (selectedFileType.includes("image/") ? (
                <img
                  src={pdfUrl}
                  alt="Preview"
                  style={{ width: "100%", height: "auto" }}
                />
              ) : selectedFileType === "application/pdf" ? (
                <Document
                  file={pdfUrl}
                  onLoadSuccess={({ numPages }) => {
                    setPageNumber(1);
                  }}
                >
                  <Page
                    pageNumber={pageNumber}
                    width={
                      pdfPreviewRef.current
                        ? pdfPreviewRef.current.offsetWidth - 40
                        : 600
                    }
                  />
                </Document>
              ) : selectedFileType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                <div>
                  <PostJobModal name="doc" />
                </div>
              ) : (
                <div>Unsupported file format.</div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Applicantprofile;

function resizeImage(file, maxWidth, maxHeight, callback) {
  const reader = new FileReader();
  reader.onload = (event) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");

      // Check if the original file is PNG to potentially preserve transparency
      if (file.type === "image/png") {
        ctx.fillStyle = "transparent";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }

      ctx.drawImage(img, 0, 0, width, height);

      const outputFormat =
        file.type === "image/png" ? "image/png" : "image/jpeg";
      // Use the correct MIME type for the blob, matching the original file type
      ctx.canvas.toBlob(
        (blob) => {
          const resizedFile = new File([blob], file.name, {
            type: outputFormat,
            lastModified: Date.now(),
          });
          callback(resizedFile);
        },
        outputFormat, // Correct MIME type for output
        outputFormat === "image/png" ? 1.0 : 0.7 // Adjust quality as needed
      );
    };
    img.src = event.target.result;
  };
  reader.readAsDataURL(file);
}

const beforeUpload = (file) => {
  const isImage = file.type.startsWith("image/");
  if (!isImage) {
    toast.error("You can only upload image files!");
    return false; // Stop the upload
  }

  const isLessThan10MB = file.size / 1024 / 1024 < 10; // Change the size limit as needed
  if (!isLessThan10MB) {
    toast.error("Image must be smaller than 10MB!"); // Customize the error message
    return false; // Stop the upload
  }
  return new Promise((resolve, reject) => {
    resizeImage(file, 1024, 1024, (resizedFile) => {
      resolve(resizedFile);
    });
  });
};
